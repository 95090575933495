<template>
<div v-if="error" class="bh-alert bh-alert-error">
    You must add at least one question
    <button class="bh-alert-remove" v-on:click="RemoveAlert">X</button>
  </div>
  <h3 class="bh-text bh-text-left">
    Missions > <strong>Create mission</strong>
  </h3>
  <div class="row m-0">
    <div class="mission-objectives">
      <div
        v-for="(objective, i) in objectives"
        :key="i"
        class="question-container"
      >
        <div class="row">
          <div class="col-11">
            <div class="row">
              <div class="col-4">
                <p>Target</p>
                <input type="Number" v-model="objective.target" /><br />
              </div>
              <div class="col-4">
                <p>Target type</p>
                <select v-model="objective.targetType">
                  <option value="Relative">Relative</option>
                  <option value="Absolute">Absolute</option></select
                ><br />
              </div>
              <div class="col-4">
                <p>Objective</p>
                <input type="number" v-model="objective.objective" /><br />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <p>Reward type</p>
                <select v-model="rewards[i].rewardType">
                  <option value="0">Experience</option>
                  <option value="1">Currency</option>
                  <option value="2">Achievement</option>
                  <option value="3">Premium</option>
                  <option value="4">HardCurrency</option></select
                ><br />
              </div>
              <div class="col-6">
                <p>Reward</p>
                <input type="number" v-model="rewards[i].amount" /><br />
              </div>
            </div>

            <hr />
          </div>
          <div class="col-1 p-0">
            <button
              class="bh-button btn-remove"
              v-on:click="RemoveObjective(i)"
            >
              <img src="../../assets/img/icons/IconRemove.png" />
            </button>
          </div>
          <hr />
        </div>
      </div>
      <button class="bh-button bh-button-standard" v-on:click="AddObjective">
        Add objective
      </button>
    </div>
    <div class="mission-options">
      <div class="row">
        <div class="col-12">
          <p>String ID</p>
          <input
            type="text"
            v-model="missionIdString"
            placeholder="Write the string id of the mission here"
          />
        </div>
        <div class="col-6">
          <p>Difficulty</p>
          <select v-model="difficulty">
            <option value="1">Easy</option>
            <option value="2">Medium</option>
            <option value="3">Hard</option>
          </select>
        </div>
        <div class="col-6">
          <p>Challenge type</p>
          <select v-model="challengeType">
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
          </select>
        </div>
        <div class="col-6">
          <p>Status</p>
          <select v-model="status" :class="status">
            <option value="Draft">Draft</option>
            <option value="Active">Active</option>
            <option value="Paused">Paused</option>
            <option value="Archived">Archived</option>
          </select>
        </div>
      </div>

      <button v-if="!isEditing" class="bh-button bh-button-full-width" v-on:click="AddMission">
        Create mission
      </button>
      <button v-if="isEditing" class="bh-button bh-button-full-width" v-on:click="UpdateMission">
        Update mission
      </button>
    </div>
  </div>
</template>
<script>
import MissionsService from "@/services/MissionsService.js";

export default {
  data() {
    return {
      id: "",
      missionIdString: "",
      difficulty: 1,
      objectives: [],
      rewards: [],
      challengeType: "Daily",
      status: "Draft",
      isEditing: false,
      hasNoQuestions: false,
      error: "",
    };
  },
  mounted() {
    this.GetMission();
  },
  methods: {
    GetMission() {
      let id = this.$route.params.id;
      if (id) {
        return MissionsService.GetMissionById(id)
          .then(async ({ data }) => {
            this.id = data.response._id;
            this.missionIdString = data.response.idString;
            this.objectives = data.response.objectives;
            this.rewards = data.response.rewards;
            this.status = data.response.status;
            this.isEditing = true;
            console.log(this.objectives);
          })
          .catch((e) => {
            console.log(e);
            //window.location.href = "/error";
          });
      }
    },
    AddObjective() {
      this.objectives.push({
        target: 0,
        targetType: "Relative",
        objective: 0,
      });

      this.rewards.push({
        rewardType: 1,
        amount: 0,
      });
    },
    RemoveObjective(objectiveIndex) {
      this.objectives.splice(objectiveIndex, 1);
    },
    AddMission() {
      let data = {
        idString: this.missionIdString,
        difficulty: this.difficulty,
        objectives: this.objectives,
        rewards: this.rewards,
        challengeType: this.challengeType,
        status: this.status
      };

      if (this.CheckForm()) {
        return MissionsService.AddMission(data)
          .then(async ({ data }) => {
            console.log(data);
            console.log("Survey added");
            window.location.href = "/missions";
          })
          .catch((e) => {
            console.log(e);
            //window.location.href = "/error";
          });
      } else {
        this.hasNoQuestions = true;
      }
    },
    UpdateMission() {
      let data = {
        _id: this.id,
        idString: this.missionIdString,
        difficulty: this.difficulty,
        objectives: this.objectives,
        rewards: this.rewards,
        challengeType: this.challengeType,
        status: this.status
      };

      return MissionsService.UpdateMission(data)
        .then(async ({ data }) => {
          window.location.href = "/missions";
        })
        .catch((e) => {
          console.log(e);
          //window.location.href = "/error";
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    CheckForm() {
      console.log('hi');
      this.error = "";

      if (!this.missionIdString || !this.difficulty || !this.challengesType || !this.status)
        this.error = "Invalid or missing params";

      this.objectives.forEach((element) => {
        if (!element.objective || !element.target)
          this.error = "Invalid or missing params";
      });

      this.rewards.forEach((element) => {
        if (!element.rewardType || !element.amount)
          this.error = "Invalid or missing params";
      });

      if (!this.error) return true;
      else return false;
    },
    RemoveAlert(){
      this.error = '';
    }
  },
};
</script>
<style scoped>
@import "../../assets/css/Missions.css";
</style>